/* Generated by ts-generator ver. 0.0.8 */
/* tslint:disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import { StakeUiHelperI } from './StakeUiHelperI';

export class StakeUiHelperIFactory {
  static connect(address: string, signerOrProvider: Signer | Provider): StakeUiHelperI {
    return new Contract(address, _abi, signerOrProvider) as StakeUiHelperI;
  }
}

const _abi = [
  {
    inputs: [
      { internalType: 'contract IPriceOracle', name: 'priceOracle', type: 'address' },
      { internalType: 'contract BPTPriceFeedI', name: 'bptPriceFeed', type: 'address' },
      { internalType: 'address', name: 'aave', type: 'address' },
      { internalType: 'contract IStakedToken', name: 'stkAave', type: 'address' },
      { internalType: 'address', name: 'bpt', type: 'address' },
      { internalType: 'contract IStakedToken', name: 'stkBpt', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'AAVE',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'BPT',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'BPT_PRICE_FEED',
    outputs: [{ internalType: 'contract BPTPriceFeedI', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PRICE_ORACLE',
    outputs: [{ internalType: 'contract IPriceOracle', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'STAKED_AAVE',
    outputs: [{ internalType: 'contract IStakedToken', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'STAKED_BPT',
    outputs: [{ internalType: 'contract IStakedToken', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getStkAaveData',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'stakeTokenTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeCooldownSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeUnstakeWindow', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeApy', type: 'uint256' },
          { internalType: 'uint128', name: 'distributionPerSecond', type: 'uint128' },
          { internalType: 'uint256', name: 'distributionEnd', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'userCooldown', type: 'uint256' },
          { internalType: 'uint256', name: 'userIncentivesToClaim', type: 'uint256' },
          { internalType: 'uint256', name: 'userPermitNonce', type: 'uint256' },
        ],
        internalType: 'struct StakeUIHelperI.AssetUIData',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getStkBptData',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'stakeTokenTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeCooldownSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeUnstakeWindow', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeApy', type: 'uint256' },
          { internalType: 'uint128', name: 'distributionPerSecond', type: 'uint128' },
          { internalType: 'uint256', name: 'distributionEnd', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'userCooldown', type: 'uint256' },
          { internalType: 'uint256', name: 'userIncentivesToClaim', type: 'uint256' },
          { internalType: 'uint256', name: 'userPermitNonce', type: 'uint256' },
        ],
        internalType: 'struct StakeUIHelperI.AssetUIData',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getUserUIData',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'stakeTokenTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeCooldownSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeUnstakeWindow', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeApy', type: 'uint256' },
          { internalType: 'uint128', name: 'distributionPerSecond', type: 'uint128' },
          { internalType: 'uint256', name: 'distributionEnd', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'userCooldown', type: 'uint256' },
          { internalType: 'uint256', name: 'userIncentivesToClaim', type: 'uint256' },
          { internalType: 'uint256', name: 'userPermitNonce', type: 'uint256' },
        ],
        internalType: 'struct StakeUIHelperI.AssetUIData',
        name: '',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'stakeTokenTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeCooldownSeconds', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeUnstakeWindow', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardTokenPriceEth', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeApy', type: 'uint256' },
          { internalType: 'uint128', name: 'distributionPerSecond', type: 'uint128' },
          { internalType: 'uint256', name: 'distributionEnd', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'underlyingTokenUserBalance', type: 'uint256' },
          { internalType: 'uint256', name: 'userCooldown', type: 'uint256' },
          { internalType: 'uint256', name: 'userIncentivesToClaim', type: 'uint256' },
          { internalType: 'uint256', name: 'userPermitNonce', type: 'uint256' },
        ],
        internalType: 'struct StakeUIHelperI.AssetUIData',
        name: '',
        type: 'tuple',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
